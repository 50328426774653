<template>
  <b-form
    @submit.prevent
  >
    <b-row>
      <b-col lg="3">
        <b-form-group
          label="Percentage"
          label-for="percentage"
        >
          <custom-v-select
            id="percentage"
            v-model="filters.percentage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="percentageOptions"
            :clearable="true"
          />
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          label="Company"
          label-for="companies"
        >
          <custom-v-select
            id="companies"
            v-model="filters.companies"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="companyOptions"
            :clearable="true"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>

      <b-col lg="3">
        <b-form-group
          label="PO"
          label-for="po"
        >
          <custom-v-select
            id="po"
            v-model="filters.po"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="poOptions"
            :clearable="true"
          />
        </b-form-group>
      </b-col>

      <b-col lg="3">
        <b-form-group
          label="Reorder Value"
          label-for="reorder-value"
        >
          <custom-v-select
            id="reorder-value"
            v-model="filters.reorderValue"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="reorderValueOptions"
            :clearable="true"
          />
        </b-form-group>
      </b-col>

      <b-col lg="3">
        <b-form-group
          label="Sale"
          label-for="sake"
        >
          <custom-v-select
            id="sake"
            v-model="filters.sale"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="saleOptions"
            :clearable="true"
          />
        </b-form-group>
      </b-col>

      <b-col lg="3">
        <b-form-group
          label="Cost"
          label-for="cost"
        >
          <custom-v-select
            id="cost"
            v-model="filters.cost"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="costOptions"
            :clearable="true"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="displayReApply"
        lg="3"
      >
        <b-button
          variant="primary"
          class="mt-2"
          @click="$emit('re-apply')"
        >
          Re Apply Filters
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>

import {
  BRow, BCol, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import { isEqual, cloneDeep } from 'lodash'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    CustomVSelect,
    BButton,
  },
  props: {
    orderItems: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    displayReApply: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      filters: {},
      percentageOptions: [
        {
          label: 'x = 0',
          from: 0,
          to: 0,
        },
        {
          label: '0 < x <=25',
          from: 1,
          to: 25,
        },
        {
          label: '25 < x <= 100',
          from: 26,
          to: 100,
        },
        {
          label: '100 < x',
          from: 101,
          to: null,
        },
      ],
      poOptions: [
        {
          label: 'X = 0',
          from: 0,
          to: 0,
        },
        {
          label: 'X > 0',
          from: 1,
          to: null,
        },
      ],
      reorderValueOptions: [
        {
          label: 'X = 0',
          from: 0,
          to: 0,
        },
        {
          label: '0 < x <=5000',
          from: 1,
          to: 5000,
        },
        {
          label: '5000 < x <= 10000',
          from: 5001,
          to: 10000,
        },
        {
          label: '10000 < x <= 30000',
          from: 10001,
          to: 30000,
        },
        {
          label: '30000 < x <= 50000',
          from: 30001,
          to: 50000,
        },
        {
          label: '50000 < x <= 100000',
          from: 50001,
          to: 100000,
        },
        {
          label: '100000 < x',
          from: 100001,
          to: null,
        },
      ],
      saleOptions: [
        {
          label: 'X = 0',
          from: 0,
          to: 0,
        },
        {
          label: 'X > 0',
          from: 1,
          to: null,
        },
      ],
      costOptions: [
        {
          label: '0 < x <= 50',
          from: 1,
          to: 50,
        },
        {
          label: '50 < x <=100',
          from: 51,
          to: 100,
        },
        {
          label: '100 < x <= 200',
          from: 101,
          to: 200,
        },
        {
          label: '200 < x <= 300',
          from: 201,
          to: 300,
        },
        {
          label: '300 < x <= 500',
          from: 301,
          to: 500,
        },
        {
          label: '500 < x <= 1000',
          from: 501,
          to: 1000,
        },
        {
          label: '1000 < x <= 3000',
          from: 1001,
          to: 3000,
        },
        {
          label: '3000 < x',
          from: 3001,
          to: null,
        },
      ],
    }
  },
  computed: {
    companyOptions() {
      const companies = this.orderItems.map(orderItem => orderItem.company)
      return [...new Set(companies)]
    },
    out() {
      return cloneDeep(this.filters)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.filters = cloneDeep(this.value)
    },
  },
}
</script>
