<template>
  <b-modal
    v-model="showModal"
    :title="`Delete Order - ${order.company_group_name}`"
    centered
    @hidden="$emit('modal-closed')"
    @ok="submitHandler"
  >
    <b-card-text>
      Are you sure you want to delete order {{ order.company_group_name }}?
    </b-card-text>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="danger"
        @click="ok()"
      >
        Delete
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BCardText, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BCardText,
    BButton,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      saving: false,
    }
  },
  methods: {
    submitHandler(event) {
      event.preventDefault()
      this.$emit('delete-order', this.order)
      this.showModal = false
    },
  },
}
</script>
