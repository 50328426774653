<template>
  <div>
    <b-card
      v-if="orders.length > 0"
      title="Orders"
    >
      <b-row class="mb-1">
        <b-col
          lg="3"
        >
          <b-form-group
            label="Order Action"
            label-for="order-action"
          >
            <custom-v-select
              id="order-action"
              v-model="orderAction"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="orderActionOptions"
              :clearable="true"
              label="label"
              :disabled="selectedRecords.length === 0"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-button
            variant="primary"
            :disabled="!orderAction || selectedRecords.length === 0"
            class="mt-2"
            @click="applyOrderAction"
          >
            Apply
          </b-button>
          <b-button
            variant="primary"
            :disabled="selectedRecords.length === 0"
            class="mt-2 mx-1"
            @click="exportCombined"
          >
            Download
          </b-button>
        </b-col>
      </b-row>

      <b-table
        :items="orders"
        :fields="tableFields"
        responsive="sm"
        striped
        hover
        sort-by="company_group_name"
        :tbody-tr-class="rowClass"
        class="compact-table"
      >

        <template #head(select)>
          <b-form-checkbox
            v-model="allRecordsSeleted"
            :disabled="orders.length === 0"
            @change="toggleRecordsSelection"
          />
        </template>

        <template #cell(select)="data">
          <b-form-checkbox
            v-model="selectedRecords"
            :value="data.item.company_group_id"
          />
        </template>

        <template #cell(open_po)="data">
          <span :id="`open_po_col_${data.item.company_group_id}`">{{ data.item.open_po }}</span>
          <b-popover
            v-if="data.item.open_po_data.length > 0"
            :target="`open_po_col_${data.item.company_group_id}`"
            triggers="hover"
            placement="rightbottom"
            custom-class="open_po_popover"
          >
            <b-table
              class="compact-table"
              :items="data.item.open_po_data"
            />
          </b-popover>
        </template>

        <template #cell(actions)="{ item }">
          <div class="actions-wrapper">
            <feather-icon
              v-b-tooltip.hover="{boundary:'window'}"
              title="Edit Order"
              icon="EditIcon"
              class="cursor-pointer"
              size="22"
              @click="startEdit(item)"
            />

            <feather-icon
              v-b-tooltip.hover="{boundary:'window'}"
              title="Download"
              icon="DownloadIcon"
              class="cursor-pointer ml-1"
              size="22"
              @click="exportCSV(item)"
            />
            <feather-icon
              v-if="item.sent === false"
              v-b-tooltip.hover="{boundary:'window'}"
              title="Send Order"
              icon="SendIcon"
              class="cursor-pointer ml-1"
              size="22"
              @click="sendOrder = item"
            />
            <feather-icon
              v-if="item.sent === true"
              v-b-tooltip.hover="{boundary:'window'}"
              title="Order Sent"
              icon="SendIcon"
              class="order-sent ml-1"
              size="22"
            />
            <feather-icon
              v-b-tooltip.hover="{boundary:'window'}"
              title="Delete Order"
              icon="Trash2Icon"
              size="22"
              class="cursor-pointer ml-1"
              @click="deleteOrder = item"
            />
          </div>
        </template>
        <template #cell(summary.reorder_value_floor)="{item}">
          {{ formateNumber(item.summary.reorder_value_floor) }}
        </template>
        <template #cell(summary.reorder_value_ceil)="{item}">
          {{ formateNumber(item.summary.reorder_value_ceil) }}
        </template>
        <template #cell(summary.reorder_value_round)="{item}">
          {{ formateNumber(item.summary.reorder_value_round) }}
        </template>
        <template #cell(summary.reorder_value)="{item}">
          {{ formateNumber(item.summary.reorder_value) }}
        </template>

        <template #custom-foot="">
          <b-tr>
            <b-th colspan="11" />
            <b-th
              colspan="1"
              class="text-right pr-2"
            >
              {{ formateNumber(reorderValueSum) }}
            </b-th>
            <b-th colspan="1" />
          </b-tr>
        </template>
      </b-table>
    </b-card>

    <send-order
      v-if="sendOrder"
      :order="sendOrder"
      @modal-closed="sendOrder = null"
      @order-sent="onOrderSent"
    />

    <delete-order
      v-if="deleteOrder"
      :order="deleteOrder"
      @modal-closed="deleteOrder = null"
      @delete-order="deleteOrderHandler"
    />
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BTable, BTh, BTr, VBTooltip, BFormCheckbox, BFormGroup, BButton, BPopover,
} from 'bootstrap-vue'
import Papa from 'papaparse'
import { saveAs } from 'file-saver'
import { cloneDeep } from 'lodash'

import bus from '@/bus'
import SendOrder from '@/components/CreateOrder/SendOrder.vue'
import DeleteOrder from '@/components/CreateOrder/DeleteOrder.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import formatMixin from '@/mixins/formatMixin'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    SendOrder,
    DeleteOrder,
    BTable,
    BTh,
    BTr,
    CustomVSelect,
    BButton,
    BPopover,
  },
  mixins: [formatMixin],
  data() {
    return {
      sendOrder: null,
      tableFields: [
        { key: 'select' },
        { key: 'company_group_name', label: 'Group Name', sortable: true },
        { key: 'open_po', label: 'Open PO', sortable: true },
        { key: 'summary.total_items', label: 'Total Items', sortable: true },
        { key: 'summary.zero_stock_items', label: 'Zero Stock Items', sortable: true },
        { key: 'summary.less_than_x_days_items', label: 'Less than X Days Items', sortable: true },
        { key: 'summary.hash_items', label: 'Hash Items', sortable: true },
        { key: 'summary.selected_items', label: 'Selected Items', sortable: true },
        {
          key: 'summary.reorder_value_floor', label: 'Reorder Value Floor', sortable: true, class: 'text-right',
        },
        {
          key: 'summary.reorder_value_ceil', label: 'Reorder Value Ceil', sortable: true, class: 'text-right',
        },
        {
          key: 'summary.reorder_value_round', label: 'Reorder Value Round', sortable: true, class: 'text-right',
        },
        {
          key: 'summary.reorder_value', label: 'Reorder Value', sortable: true, class: 'text-right pr-2',
        },
        { key: 'actions', label: 'Actions' },
      ],
      deleteOrder: null,
      allRecordsSeleted: false,
      selectedRecords: [],
      orderAction: null,
      orderActionOptions: [
        {
          label: 'Default selection',
          value: 'default',
        },
        {
          label: 'Select zero stock items',
          value: 'is_zero_stock',
        },
        {
          label: 'Select less than x days items',
          value: 'is_less_than_x_days',
        },
        {
          label: 'Select hash items',
          value: 'is_hash_item',
        },
        {
          label: 'Select all items',
          value: 'all',
        },
      ],
      windowScrollYPos: null,
      highlightRecordId: null,
      highlightTimerId: null,
    }
  },
  computed: {
    orders: {
      get() {
        return this.$store.getters['order/orders']
      },
      set(value) {
        this.$store.commit('order/SET_ORDERS', value)
      },
    },
    reorderValueSum() {
      return +this.orders.reduce((sum, order) => sum + order.summary.reorder_value, 0).toFixed(2)
    },
  },
  watch: {
    orders: {
      handler() {
        this.selectedRecords = this.selectedRecords.filter(id => {
          const index = this.orders.findIndex(order => order.company_group_id === id)
          return index !== -1
        })
      },
      deep: true,
    },
    selectedRecords(newValue) {
      if (this.orders.length > 0 && newValue.length === this.orders.length) {
        this.allRecordsSeleted = true
      } else {
        this.allRecordsSeleted = false
      }
    },
  },
  created() {
    bus.$on('highlightOrderRow', this.onHighlightOrderRow)
  },
  destroyed() {
    bus.$off('highlightOrderRow', this.onHighlightOrderRow)
  },
  methods: {
    onHighlightOrderRow(companyGroupId) {
      window.scroll(0, this.windowScrollYPos)
      this.highlightRecordId = companyGroupId
      this.highlightTimerId = setTimeout(() => {
        this.highlightRecordId = null
      }, 3000)
    },
    exportCSV(order) {
      const items = this.getOrderItemsForExport(order)
      this.downloadCSV(items, `${order.company_group_name} Order.csv`)
    },
    exportCombined() {
      const selectedOrders = this.orders.filter(order => this.selectedRecords.includes(order.company_group_id))
      let items = []
      selectedOrders.forEach(order => {
        items = items.concat(this.getOrderItemsForExport(order))
      })
      this.downloadCSV(items, 'Combined Order.csv')
    },
    getOrderItemsForExport(order) {
      let items = order.records.filter(item => item.selected === true)
      items = items.map(item => ({
        'ITEM NAME': item.item,
        PO: item.po,
        SALE: item.sale,
        STOCK: item.stock,
        'REORDER ITEMS COUNT': item.reorder_items_count,
        'REORDER ITEMS COUNT ROUND': item.reorder_items_count_round,
        COST: item.cost,
        'REORDER VALUE': item.reorder_value,
        'REORDER VALUE ROUND': item.reorder_value_round,
        PERCENTAGE: item.percentage,
        'BOX QUANTITY': item.box_qty,
        COMPANY: item.company,
        'ITEM CODE': item.code,
        'REQUIRED REORDER ITEMS COUNT': item.required_reorder_items_count,
        'REORDER ITEMS COUNT FLOOR': item.reorder_items_count_floor,
        'REORDER ITEMS COUNT CEIL': item.reorder_items_count_ceil,
        'REORDER VALUE FLOOR': item.reorder_value_floor,
        'REORDER VALUE CEIL': item.reorder_value_ceil,
      }))
      return items
    },
    downloadCSV(items, fileName) {
      const csvData = Papa.unparse(items, {
        delimiter: ',',
        header: true,
        newline: '\r\n',
        skipEmptyLines: false,
      })
      const blob = new Blob([csvData], { type: 'data:text/csv;charset=utf-8' })
      saveAs(blob, fileName)
    },
    onOrderSent({ companyGroupId }) {
      const orderIndex = this.orders.findIndex(order => order.company_group_id === companyGroupId)
      this.orders[orderIndex].sent = true
      this.exportCSV(this.orders[orderIndex])
    },
    rowClass(item, type) {
      let className = ''
      if (!item && type === 'row') {
        return className
      }

      if (this.highlightRecordId === item.company_group_id) {
        className = 'row-highlight-after-edit'
      } else if (item.sent === true) {
        className = 'row-success'
      } else if (item.open_po > 0) {
        className = 'row-danger'
      }

      return className
    },
    deleteOrderHandler(order) {
      this.$store.commit('order/DELETE_ORDER', order)
    },
    toggleRecordsSelection(checked) {
      this.selectedRecords = checked ? this.orders.map(order => order.company_group_id) : []
    },
    applyOrderAction() {
      const orders = cloneDeep(this.orders)
      for (let orderIdex = 0; orderIdex < orders.length; orderIdex += 1) {
        const order = orders[orderIdex]
        if (!this.selectedRecords.includes(order.company_group_id)) {
          // eslint-disable-next-line no-continue
          continue
        }

        for (let orderItemIndex = 0; orderItemIndex < order.records.length; orderItemIndex += 1) {
          const orderItem = order.records[orderItemIndex]
          let selected = false

          if (this.orderAction.value === 'all') {
            selected = true
          } else if (this.orderAction.value === 'default') {
            if (order.open_po > 0) {
              if (!(orderItem.is_hash_item || orderItem.po > 0)) {
                selected = true
              }
            } else if (!orderItem.is_hash_item) {
              selected = true
            }
          } else if (orderItem[this.orderAction.value]) {
            selected = true
          }

          orders[orderIdex].records[orderItemIndex].selected = selected
        }
      }
      this.orders = orders
    },
    startEdit(item) {
      clearTimeout(this.highlightTimerId)
      this.windowScrollYPos = window.scrollY
      this.$store.commit('order/SET_EDIT_ORDER_ID', item.company_group_id)
    },
  },
}
</script>

<style scoped>
.order-sent {
    opacity: 50%
}
.actions-wrapper {
    min-width: 130px;
}
</style>

<style>
.row-highlight-after-edit {
    background-color: #e4e1ff !important
}
.open_po_popover {
    max-width: 500px;
}
</style>
