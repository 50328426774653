<template>
  <b-card no-body>
    <b-card-header
      role="button"
      @click="expanded = !expanded"
    >
      <div>
        <h4 class="my-0">
          Upload Data
        </h4>
      </div>
    </b-card-header>

    <b-card-body
      v-show="expanded"
      class="table-card-body"
    >
      <div
        v-if="loading"
        class="text-center"
      >
        <b-spinner
          label="Small Spinner"
          variant="primary"
        />
      </div>

      <b-alert
        v-if="!loading"
        variant="danger"
        :show="errorMessage ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </b-alert>

      <b-form
        v-if="!loading"
        @submit.prevent="submit"
      >
        <b-row>
          <b-col lg="2">
            <b-form-group
              label="Stock file"
              label-for="stock-file"
            >
              <b-form-file
                id="stock-file"
                v-model="stockFile"
                placeholder="Stock file"
                accept=".csv, .xls"
              />
            </b-form-group>
            <b-form-checkbox
              v-model="usePreviousFile"
            >
              Use previously uploaded stock file
            </b-form-checkbox>
          </b-col>

          <b-col lg="2">
            <b-form-group
              label="For days"
              label-for="for-days"
            >
              <b-form-input
                id="for-days"
                v-model="forDays"
                type="number"
                placeholder="For days"
              />
            </b-form-group>
          </b-col>

          <b-col lg="2">
            <b-form-group
              label="Number of days"
              label-for="number-of-days"
            >
              <b-form-input
                id="number-of-days"
                v-model="numberOfDays"
                type="number"
                placeholder="Number of days"
              />
            </b-form-group>
          </b-col>

          <b-col lg="2">
            <b-form-group
              label="Less then x days"
              label-for="less-then-x-days"
            >
              <b-form-input
                id="less-then-x-days"
                v-model="lessThenXDays"
                type="number"
                placeholder="Less then x days"
              />
            </b-form-group>
          </b-col>

          <b-col lg="2">
            <b-form-group
              label="Min percentage"
              label-for="min-percentage"
            >
              <b-form-input
                id="min-percentage"
                v-model="minPercentage"
                type="number"
                placeholder="Min percentage"
              />
            </b-form-group>
          </b-col>

          <b-col lg="2">
            <b-button
              variant="primary"
              :disabled="submitting"
              type="submit"
              class="mt-2"
            >
              Process data
              <b-spinner
                v-if="submitting"
                small
                label="Small Spinner"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>

  </b-card>
</template>

<script>

import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BFormFile, BButton, BSpinner, BAlert, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BSpinner,
    BAlert,
    BCardHeader,
    BCardBody,
    BFormCheckbox,
  },
  props: {
    editing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      stockFile: null,
      usePreviousFile: false,
      forDays: null,
      numberOfDays: null,
      lessThenXDays: null,
      minPercentage: null,
      errorMessage: null,
      submitting: false,
      expanded: true,
    }
  },
  watch: {
    usePreviousFile(newValue) {
      if (newValue) {
        this.stockFile = null
      }
    },
    stockFile(newValue) {
      if (newValue) {
        this.usePreviousFile = false
      }
    },
  },
  created() {
    this.fetchDetails()
  },
  methods: {
    fetchDetails() {
      this.loading = true
      axios.get('/default_settings')
        .then(res => {
          const resData = res.data
          this.forDays = resData.for_days
          this.numberOfDays = resData.number_of_days
          this.lessThenXDays = resData.less_than_x_days
          this.minPercentage = resData.min_percentage
          this.loading = false
        })
        .catch(error => {
          this.errorMessage = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
    submit() {
      if (this.editing) {
        // eslint-disable-next-line no-alert
        if (!window.confirm('Are you sure you want to re-upload data? Changes made to orders will not be saved.')) {
          return
        }
      }
      this.errorMessage = null
      this.submitting = true

      const formData = new FormData()
      formData.append('stock_file', this.stockFile)
      formData.append('use_previous_file', this.usePreviousFile)
      formData.append('for_days', this.forDays)
      formData.append('number_of_days', this.numberOfDays)
      formData.append('less_then_x_days', this.lessThenXDays)
      formData.append('min_percentage', this.minPercentage)

      axios.post('/process_stock_data',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          const orders = res.data.data.map(order => ({
            ...order,
            sent: false,
            records: order.records.map(record => ({
              ...record,
              reorder_items_count: record.reorder_items_count_round,
              reorder_value: +(record.reorder_items_count_round * record.cost).toFixed(2),
            })),
          }))
          this.$store.commit('order/SET_ORDERS', orders)
          this.$store.commit('order/SET_EDIT_ORDER_ID', null)
          this.submitting = false
          this.expanded = false
        })
        .catch(error => {
          this.errorMessage = error?.response?.data?.error || 'Something went wrong'
          this.$store.commit('order/SET_ORDERS', [])
          this.$store.commit('order/SET_EDIT_ORDER_ID', null)
          this.submitting = false
        })
    },
  },
}
</script>
