<template>
  <div>
    <upload-data :editing="isEditing" />
    <orders v-show="editOrderId === null" />
    <edit-order
      v-if="editOrderId !== null"
      :id="editOrderId"
    />
  </div>
</template>

<script>
import UploadData from '@/components/CreateOrder/UploadData.vue'
import Orders from '@/components/CreateOrder/Orders.vue'
import EditOrder from '@/components/CreateOrder/EditOrder.vue'

export default {
  components: {
    UploadData,
    Orders,
    EditOrder,
  },
  data() {
    return {
      isEditing: false,
    }
  },
  computed: {
    orders: {
      get() {
        return this.$store.getters['order/orders']
      },
      set(value) {
        this.$store.commit('order/SET_ORDERS', value)
      },
    },
    editOrderId: {
      get() {
        return this.$store.getters['order/editOrderId']
      },
      set(value) {
        this.$store.commit('order/SET_EDIT_ORDER_ID', value)
      },
    },
  },
  watch: {
    orders: {
      handler() {
        this.isEditing = this.orders.length !== 0
      },
      deep: true,
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNavigation)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNavigation)
  },
  destroyed() {
    this.$store.dispatch('order/reset')
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Are you sure you want to leave the page? Changes made to orders will not be saved.')) {
        return
      }
    }
    next()
  },
  methods: {
    preventNavigation(event) {
      if (!this.isEditing) {
        return
      }
      event.preventDefault()
      // eslint-disable-next-line no-param-reassign
      event.returnValue = ''
    },
  },
}
</script>
