<template>
  <b-card
    :title="`Edit Order - ${order.company_group_name}`"
  >
    <edit-order-filers
      v-model="filters"
      :order-items="items"
      :display-re-apply="needReApplyFilter"
      @re-apply="applyFilter"
    />

    <hr>

    <b-row>
      <b-col lg="3">
        <b-form-group
          label="Reset Reorder Items Count To"
          label-for="reset-reorder-items-count"
        >
          <custom-v-select
            id="reset-reorder-items-count"
            v-model="resetReorderItemsCountValue"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="resetReorderItemsCountOptions"
            :clearable="true"
            :reduce="option => option.value"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-button
          variant="primary"
          class="mt-2"
          :disabled="!resetReorderItemsCountValue"
          @click="resetReorderItemsCount"
        >
          Apply
        </b-button>
      </b-col>
    </b-row>

    <b-table
      responsive
      :items="items"
      :fields="tableFields"
      sticky-header="calc(100vh - 190px)"
      :tbody-tr-class="rowClass"
      hover
      striped
      class="compact-table edit-order-table"
      filter="dummy"
      :filter-function="(item) => item.matchesCriteria"
      :no-local-sorting="true"
      @sort-changed="sortingChanged"
    >
      <template #head(selected)>
        <b-form-checkbox
          :checked="allRecordsSeleted"
          :disabled="filteredRecords.length === 0"
          @change="toggleRecordsSelection"
        />
      </template>

      <template #cell(selected)="data">
        <b-form-checkbox
          v-model="data.item.selected"
        />
      </template>

      <template #cell(reorder_items_count)="data">
        <b-form-input
          v-model.number="data.item.reorder_items_count"
          @input="data.item.reorder_value = +($event * data.item.cost).toFixed(2)"
        />
      </template>
      <template #cell(cost)="{item}">
        {{ formateNumber(item.cost) }}
      </template>
      <template #cell(reorder_value)="{item}">
        {{ formateNumber(item.reorder_value) }}
      </template>
      <template #cell(reorder_value_round)="{item}">
        {{ formateNumber(item.reorder_value_round) }}
      </template>
      <template #cell(reorder_value_floor)="{item}">
        {{ formateNumber(item.reorder_value_floor) }}
      </template>
      <template #cell(reorder_value_ceil)="{item}">
        {{ formateNumber(item.reorder_value_ceil) }}
      </template>
    </b-table>

    <b-row>
      <b-col class="text-right">

        <b-button
          variant="secondary"
          class="mx-1"
          @click="cancel"
        >
          Cancel
        </b-button>

        <b-button
          variant="primary"
          @click="save"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BFormInput, BFormCheckbox, BButton, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import { cloneDeep } from 'lodash'

import bus from '@/bus'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import formatMixin from '@/mixins/formatMixin'
import EditOrderFilers from './EditOrderFilers.vue'

export default {
  components: {
    BCard,
    BTable,
    BFormInput,
    BFormCheckbox,
    BButton,
    BRow,
    BCol,
    EditOrderFilers,
    BFormGroup,
    CustomVSelect,
  },
  mixins: [formatMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      tableFields: [
        { key: 'selected', label: 'Selected', sortable: true },
        { key: 'item', label: 'Item Name', sortable: true },
        { key: 'po', label: 'PO', sortable: true },
        { key: 'sale', label: 'Sale', sortable: true },
        { key: 'stock', label: 'Stock', sortable: true },
        { key: 'reorder_items_count', label: 'Reorder items count', sortable: true },
        { key: 'reorder_items_count_round', label: 'Reorder items count round', sortable: true },
        {
          key: 'cost', label: 'Cost', sortable: true, class: 'text-right pr-2',
        },
        {
          key: 'reorder_value', label: 'Reorder Value', sortable: true, class: 'text-right pr-2',
        },
        {
          key: 'reorder_value_round', label: 'Reorder Value Round', sortable: true, class: 'text-right pr-2',
        },
        { key: 'percentage', label: 'Percentage', sortable: true },
        { key: 'box_qty', label: 'Box Quantity', sortable: true },
        { key: 'company', label: 'Company', sortable: true },
        { key: 'code', label: 'Item Code', sortable: true },
        { key: 'required_reorder_items_count', label: 'Required Reroder Items Count', sortable: true },
        { key: 'reorder_items_count_floor', label: 'Reorder Items count floor', sortable: true },
        { key: 'reorder_items_count_ceil', label: 'Reorder items count ceil', sortable: true },
        {
          key: 'reorder_value_floor', label: 'Reorder Value floor', sortable: true, class: 'text-right pr-2',
        },
        {
          key: 'reorder_value_ceil', label: 'Reorder Value Ceil', sortable: true, class: 'text-right pr-2',
        },
      ],
      filters: {
        percentage: null,
        companies: [],
        po: null,
        reorderValue: null,
        sale: null,
        cost: null,
      },
      resetReorderItemsCountValue: null,
      resetReorderItemsCountOptions: [
        {
          label: 'Reorder Items Count Round',
          value: 'reorder_items_count_round',
        },
        {
          label: 'Reorder Items Count Floor',
          value: 'reorder_items_count_floor',
        },
        {
          label: 'Reorder Items Count Ceil',
          value: 'reorder_items_count_ceil',
        },
      ],
      sortBy: null,
      sortDesc: false,
    }
  },
  computed: {
    orders() {
      return this.$store.getters['order/orders']
    },
    order() {
      return this.orders.find(order => order.company_group_id === this.id)
    },
    allRecordsSeleted() {
      if (this.filteredRecords.length > 0
        && this.filteredRecords.length === this.filteredRecords.filter(record => record.selected).length) {
        return true
      }
      return false
    },
    filteredRecords() {
      return this.items.filter(item => item.matchesCriteria)
    },
    needReApplyFilter() {
      return !this.filteredRecords.every(item => this.isItemMatchesCriteria(item))
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.applyFilter()
      },
    },
  },
  created() {
    this.items = cloneDeep(this.order.records)
    this.applyFilter()
  },
  methods: {
    rowClass(item, type) {
      let className = ''
      if (item && type === 'row' && item.po > 0) {
        className = 'row-danger'
      }
      return className
    },
    save() {
      const order = {
        ...this.order,
        records: this.items,
      }
      this.$store.commit('order/UPDATE_ORDER', order)
      this.$store.commit('order/SET_EDIT_ORDER_ID', null)
      this.$nextTick(() => {
        bus.$emit('highlightOrderRow', this.order.company_group_id)
      })
    },
    cancel() {
      this.$store.commit('order/SET_EDIT_ORDER_ID', null)
      this.$nextTick(() => {
        bus.$emit('highlightOrderRow', this.order.company_group_id)
      })
    },
    valueInRange(value, from, to) {
      if (from === to) {
        if (value === from) {
          return true
        }
      } else if (to) {
        if (value >= from && value <= to) {
          return true
        }
      } else if (value >= from) {
        return true
      }
      return false
    },
    applyFilter() {
      this.items = this.items.map(item => ({
        ...item,
        matchesCriteria: this.isItemMatchesCriteria(item),
      }))
      this.applySort()
    },
    isItemMatchesCriteria(item) {
      const { filters } = this
      if (filters.percentage) {
        const result = this.valueInRange(item.percentage, filters.percentage.from, filters.percentage.to)
        if (!result) {
          return false
        }
      }

      if (filters.companies.length > 0) {
        const result = filters.companies.includes(item.company)
        if (!result) {
          return false
        }
      }

      if (filters.po) {
        const result = this.valueInRange(item.po, filters.po.from, filters.po.to)
        if (!result) {
          return false
        }
      }

      if (filters.reorderValue) {
        const result = this.valueInRange(item.reorder_value, filters.reorderValue.from, filters.reorderValue.to)
        if (!result) {
          return false
        }
      }

      if (filters.sale) {
        const result = this.valueInRange(item.sale, filters.sale.from, filters.sale.to)
        if (!result) {
          return false
        }
      }

      if (filters.cost) {
        const result = this.valueInRange(item.cost, filters.cost.from, filters.cost.to)
        if (!result) {
          return false
        }
      }

      return true
    },
    toggleRecordsSelection(checked) {
      const filteredCodes = this.filteredRecords.map(record => record.code)
      const items = cloneDeep(this.items)

      for (let itemIndex = 0; itemIndex < items.length; itemIndex += 1) {
        const item = items[itemIndex]
        if (filteredCodes.includes(item.code)) {
          items[itemIndex].selected = checked
        }
      }
      this.items = items
    },
    resetReorderItemsCount() {
      const filteredCodes = this.filteredRecords.map(record => record.code)
      const items = cloneDeep(this.items)

      for (let itemIndex = 0; itemIndex < items.length; itemIndex += 1) {
        const item = items[itemIndex]
        if (filteredCodes.includes(item.code)) {
          items[itemIndex].reorder_items_count = item[this.resetReorderItemsCountValue]
          items[itemIndex].reorder_value = +(item.reorder_items_count * item.cost).toFixed(2)
        }
      }
      this.items = items
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.applySort()
    },
    applySort() {
      if (!this.sortBy) {
        return
      }

      let items = cloneDeep(this.items)
      items.sort((aRow, bRow) => {
        const a = aRow[this.sortBy]
        const b = bRow[this.sortBy]

        if (a < b) { return -1 }
        if (a > b) { return 1 }
        return 0
      })
      if (this.sortDesc) {
        items = items.reverse()
      }
      this.items = items
    },
  },
}
</script>

<style lang="scss">
.edit-order-table {
    font-weight: bold;

    th, td {
        padding: 0.1rem 0.1rem;
    }

    input {
        height: 1.7rem;
        font-weight: bold;
    }
}
</style>
